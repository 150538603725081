import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import Footer from '../components/Footer'

export default class BlogPostList extends React.Component {
  render() {
    const posts = this.props.data.allJsonJson.edges
    const links = this.props.pageContext.links
    const context = this.props.pageContext
    return (
      <Layout>
        <div id="blog-list">
          <header id="header">
            <Link to="/" >
              <div className="logo">
                {/* <span className="icon fa-skull-crossbones"></span> */}
              </div></Link>
          </header>
          <h1>Undead Blog Posts</h1>
          <div className="link-col">
            {links.map((node) => {
              return <div key={node.path} className="link-col-item">
                <Link to={"/" + node.path}>
                  <div className="banner-image">
                    {/* <img src={"/wp-content/uploads/"+node.featured_media.media_details.file} /> */}
                    <div dangerouslySetInnerHTML={{ __html: node.title }} className="banner-text"></div>
                  </div>
                </Link>

              </div>
            })}

          </div>
          <div className="link-row">
            {
              context.currentPage > 1 && (<Link to={"/blog/" + (context.currentPage - 1)} className="link"><p>Previous</p></Link>)
            }
            <Link to={"/blog/" + (context.currentPage + 1)} className="link"><p>Next</p></Link>
          </div>
          <Footer />
        </div>
      </Layout>
    )
  }
}

export const blogListQuery = graphql`
  query blogListQuery($skip: Int!, $limit: Int!) {
    allJsonJson(limit: $limit, skip: $skip, sort: {fields: date, order: DESC}) {
      edges {
        node {
          date(formatString: "MMMM Do, YYYY")
          slug
          title
          featured_media {
            media_details {
              file
            }
          }
        }
      }
    }
  }
  `